import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { PortofolioComponent } from './portofolio/portofolio.component';
import { ContactComponent } from './contact/contact.component';
import { TeamComponent } from './team/team.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { HomePageComponent } from './home-page/home-page.component';

import { routing } from './app.routing';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AboutUsComponent } from './about-us/about-us.component';
import { TrustedComponent } from './trusted/trusted.component';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';


@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    FooterComponent,
    AboutComponent,
    ServicesComponent,
    PortofolioComponent,
    ContactComponent,
    TeamComponent,
    CalculatorComponent,
    TestimonialsComponent,
    HomePageComponent,
    AboutUsComponent,
    TrustedComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    routing,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
