import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '../preloader.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  constructor(private preloaderService: PreloaderService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.preloaderService.stop();
   }, 1500);
  }

}
