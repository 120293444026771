<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<link href="/../../assets/styles/flags.css" rel="stylesheet" />

<!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Calculator</h1>
            </div>
        </div>
    </div>
</header>

<!-- Calculator -->
<section class="page-section" id="calculator">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 text-center mt-4 pt-5">
                <img src="../../assets/images/bg-calculator.png" alt="Courier" class="courier-bg"/>
            </div>
            <div class="col-lg-6">
                <h3>Estimare cost</h3>
                <p>In această secțiune se pot calcula tarifele estimative pentru toate tipurile de servicii oferite de CMD Truck.</p>
                <forms>
                    <div class="row mt-5">
                        <div class="col-sm-4">
                            <label>Tipul de livrare:</label>
                        </div>
                        <div class="col-sm-8">
                            <select id="select-delivery" name="delivery">
                                <option></option>
                                <option value="standard">Standard</option>
                                <option value="express">Express</option>
                                <option value="vip">VIP</option>
                              </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Țara de colectare:</label>
                        </div>
                        <div class="col-sm-8">
                            <select id="select-location-from" name="location-from">
                                <option></option>
                                <option value="AF">Afganistan</option>
                                <option value="ZA">African de Sud</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AD">Andora</option>
                                <option value="GB">Anglia (Regatul Unit)</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua și Barbuda</option>
                                <option value="AN">Antilele Olandeze</option>
                                <option value="SA">Arabia Saudită</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaidjan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladeș</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarusia</option>
                                <option value="BE">Belgia</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermude</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BQ">Bonaire, Sfântul Eustatius și Saba</option>
                                <option value="BA">Bosnia și Herțegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BR">Brazilia</option>
                                <option value="BN">Brunei</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodgia</option>
                                <option value="CM">Camerun</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Capul Verde</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CY">Cipru</option>
                                <option value="CI">Coasta de Fildeș</option>
                                <option value="CO">Columbia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="KP">Coreea de Nord</option>
                                <option value="CR">Costa Rica</option>
                                <option value="HR">Croația</option>
                                <option value="CW">Curaçao</option>
                                <option value="DK">Danemarca</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egipt</option>
                                <option value="SV">El Salvador</option>
                                <option value="CH">Elveția</option>
                                <option value="AE">Emiratele Arabe Unite</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Etiopia</option>
                                <option value="RU">Federația Rusă</option>
                                <option value="FJ">Fiji</option>
                                <option value="PH">Fillipine</option>
                                <option value="FI">Finlanda</option>
                                <option value="FR">Franța</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="GS">Georgia de Sud și Insulele Sandwich</option>
                                <option value="DE">Germania</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Grecia</option>
                                <option value="GD">Grenada</option>
                                <option value="GL">Groenlanda</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GF">Guiana Franceză</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GN">Guineea</option>
                                <option value="GQ">Guineea Ecuatorială</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="BV">Insula Bouvet</option>
                                <option value="HM">Insula Heard și Insulele McDonald</option>
                                <option value="AX">Insulele Aland</option>
                                <option value="KY">Insulele Caiman</option>
                                <option value="IC">Insulele Canare </option>
                                <option value="CX">Insulele Christmas</option>
                                <option value="CC">Insulele Cocos (Keeling)</option>
                                <option value="CK">Insulele Cook </option>
                                <option value="FK">Insulele Falkland (Malvinas)</option>
                                <option value="FO">Insulele Faroe </option>
                                <option value="IM">Insulele Man</option>
                                <option value="MP">Insulele Marianei de Nord</option>
                                <option value="MH">Insulele Marshall</option>
                                <option value="NF">Insulele Norfolk</option>
                                <option value="SB">Insulele Solomon</option>
                                <option value="TC">Insulele Turks și Caicos</option>
                                <option value="VG">Insulele Virgine, Marea Britanie</option>
                                <option value="VI">Insulele Virgine, S.U.A.</option>
                                <option value="JO">Iordania</option>
                                <option value="IQ">Irak</option>
                                <option value="IE">Irlanda</option>
                                <option value="IS">Islanda</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italia</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japonia</option>
                                <option value="JE">Jersey</option>
                                <option value="KG">Kârgâzstan</option>
                                <option value="KZ">Kazahstan</option>
                                <option value="KE">Kenia</option>
                                <option value="KI">Kiribati</option>
                                <option value="KW">Kuweit</option>
                                <option value="LB">Lebanon</option>
                                <option value="LV">Letonia</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libia</option>
                                <option value="LT">Lituania</option>
                                <option value="LU">Luxemburg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia</option>
                                <option value="MG">Madagascar</option>
                                <option value="MY">Malaezia</option>
                                <option value="MW">Malawi</option>
                                <option value="MV">Maldive</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MA">Maroc</option>
                                <option value="MQ">Martinica</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexic</option>
                                <option value="MM">Mianmar</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="MS">Montserrat</option>
                                <option value="MZ">Mozambic</option>
                                <option value="ME">Muntenegru</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NO">Norvegia</option>
                                <option value="NC">Noua Caledonie</option>
                                <option value="NZ">Noua Zelandă</option>
                                <option value="NL">Olanda</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua Noua Guinee</option>
                                <option value="PY">Paraguai</option>
                                <option value="PE">Peru</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PF">Polinezia franceză</option>
                                <option value="PL">Polonia</option>
                                <option value="PR">Porto Rico</option>
                                <option value="PT">Portugalia</option>
                                <option value="LI">Principatul Liechtenstein</option>
                                <option value="QA">Qatar</option>
                                <option value="LS">Regatul Lesoto</option>
                                <option value="CF">Republica Africa Centrală</option>
                                <option value="CZ">Republica Cehă</option>
                                <option value="KR">Republica Corea</option>
                                <option value="CD">Republica Democrată Congo</option>
                                <option value="LA">Republica Democrată Populară Laos</option>
                                <option value="DO">Republica Dominicană</option>
                                <option value="IR">Republica Islamică Iran</option>
                                <option value="MD">Republica Moldova</option>
                                <option value="SC">Republica Seychelles</option>
                                <option value="ZW">Republica Zimbabwe</option>
                                <option value="RE">Réunion</option>
                                <option value="RO">România</option>
                                <option value="RW">Ruanda</option>
                                <option value="EH">Sahara de Vest</option>
                                <option value="WS">Samoa</option>
                                <option value="AS">Samoa Americană</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome și Principe</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia/Kosovo</option>
                                <option value="SH">Sfânta Elena, Înălțarea și Tristan Da Cunha</option>
                                <option value="LC">Sfânta Lucia</option>
                                <option value="BL">Sfântul Bartolomeu</option>
                                <option value="KN">Sfântul Kitts și Nevis</option>
                                <option value="SX">Sfântul Maarten (Partea Olandeză)</option>
                                <option value="MF">Sfântul Martin (Partea Franceză)</option>
                                <option value="PM">Sfântul Pierre și Miquelon</option>
                                <option value="VC">Sfântul Vicențiu și Grenadinele</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SK">Slovacia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SO">Somalia</option>
                                <option value="ES">Spania</option>
                                <option value="ES*">Spania</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="FM">Statele Federale ale Micronesiei</option>
                                <option value="UM">Statele Minore Indepartate ale Statelor Unite</option>
                                <option value="US">Statele Unite ale Americii (SUA)</option>
                                <option value="SD">Sudan</option>
                                <option value="SE">Suedia</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard și Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="TJ">Tadjikistan</option>
                                <option value="TH">Tailanda</option>
                                <option value="TW">Taiwan, Provincia Chinei</option>
                                <option value="TZ">Tanzania</option>
                                <option value="TF">Teritoriile de Sud Franceze</option>
                                <option value="IO">Teritoriul Britanic din Oceanul Indian</option>
                                <option value="PS">Teritoriul Palestinian Ocupat</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad și Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turcia</option>
                                <option value="TM">Turcmenistan</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UA">Ucraina</option>
                                <option value="UG">Uganda</option>
                                <option value="HU">Ungaria</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VA">Vatican (Statul Cetății Vaticanului)</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Vietnam</option>
                                <option value="WF">Wallis și Futuna</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Țara de livrare:</label>
                        </div>
                        <div class="col-sm-8">
                            <select id="select-location-to" name="location-to">
                                <option></option>
                                <option value="AF">Afganistan</option>
                                <option value="ZA">African de Sud</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AD">Andora</option>
                                <option value="GB">Anglia (Regatul Unit)</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua și Barbuda</option>
                                <option value="AN">Antilele Olandeze</option>
                                <option value="SA">Arabia Saudită</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaidjan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladeș</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarusia</option>
                                <option value="BE">Belgia</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermude</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BQ">Bonaire, Sfântul Eustatius și Saba</option>
                                <option value="BA">Bosnia și Herțegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BR">Brazilia</option>
                                <option value="BN">Brunei</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodgia</option>
                                <option value="CM">Camerun</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Capul Verde</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CY">Cipru</option>
                                <option value="CI">Coasta de Fildeș</option>
                                <option value="CO">Columbia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="KP">Coreea de Nord</option>
                                <option value="CR">Costa Rica</option>
                                <option value="HR">Croația</option>
                                <option value="CW">Curaçao</option>
                                <option value="DK">Danemarca</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egipt</option>
                                <option value="SV">El Salvador</option>
                                <option value="CH">Elveția</option>
                                <option value="AE">Emiratele Arabe Unite</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Etiopia</option>
                                <option value="RU">Federația Rusă</option>
                                <option value="FJ">Fiji</option>
                                <option value="PH">Fillipine</option>
                                <option value="FI">Finlanda</option>
                                <option value="FR">Franța</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="GS">Georgia de Sud și Insulele Sandwich</option>
                                <option value="DE">Germania</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Grecia</option>
                                <option value="GD">Grenada</option>
                                <option value="GL">Groenlanda</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GF">Guiana Franceză</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GN">Guineea</option>
                                <option value="GQ">Guineea Ecuatorială</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="BV">Insula Bouvet</option>
                                <option value="HM">Insula Heard și Insulele McDonald</option>
                                <option value="AX">Insulele Aland</option>
                                <option value="KY">Insulele Caiman</option>
                                <option value="IC">Insulele Canare </option>
                                <option value="CX">Insulele Christmas</option>
                                <option value="CC">Insulele Cocos (Keeling)</option>
                                <option value="CK">Insulele Cook </option>
                                <option value="FK">Insulele Falkland (Malvinas)</option>
                                <option value="FO">Insulele Faroe </option>
                                <option value="IM">Insulele Man</option>
                                <option value="MP">Insulele Marianei de Nord</option>
                                <option value="MH">Insulele Marshall</option>
                                <option value="NF">Insulele Norfolk</option>
                                <option value="SB">Insulele Solomon</option>
                                <option value="TC">Insulele Turks și Caicos</option>
                                <option value="VG">Insulele Virgine, Marea Britanie</option>
                                <option value="VI">Insulele Virgine, S.U.A.</option>
                                <option value="JO">Iordania</option>
                                <option value="IQ">Irak</option>
                                <option value="IE">Irlanda</option>
                                <option value="IS">Islanda</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italia</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japonia</option>
                                <option value="JE">Jersey</option>
                                <option value="KG">Kârgâzstan</option>
                                <option value="KZ">Kazahstan</option>
                                <option value="KE">Kenia</option>
                                <option value="KI">Kiribati</option>
                                <option value="KW">Kuweit</option>
                                <option value="LB">Lebanon</option>
                                <option value="LV">Letonia</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libia</option>
                                <option value="LT">Lituania</option>
                                <option value="LU">Luxemburg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia</option>
                                <option value="MG">Madagascar</option>
                                <option value="MY">Malaezia</option>
                                <option value="MW">Malawi</option>
                                <option value="MV">Maldive</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MA">Maroc</option>
                                <option value="MQ">Martinica</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexic</option>
                                <option value="MM">Mianmar</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="MS">Montserrat</option>
                                <option value="MZ">Mozambic</option>
                                <option value="ME">Muntenegru</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NO">Norvegia</option>
                                <option value="NC">Noua Caledonie</option>
                                <option value="NZ">Noua Zelandă</option>
                                <option value="NL">Olanda</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua Noua Guinee</option>
                                <option value="PY">Paraguai</option>
                                <option value="PE">Peru</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PF">Polinezia franceză</option>
                                <option value="PL">Polonia</option>
                                <option value="PR">Porto Rico</option>
                                <option value="PT">Portugalia</option>
                                <option value="LI">Principatul Liechtenstein</option>
                                <option value="QA">Qatar</option>
                                <option value="LS">Regatul Lesoto</option>
                                <option value="CF">Republica Africa Centrală</option>
                                <option value="CZ">Republica Cehă</option>
                                <option value="KR">Republica Corea</option>
                                <option value="CD">Republica Democrată Congo</option>
                                <option value="LA">Republica Democrată Populară Laos</option>
                                <option value="DO">Republica Dominicană</option>
                                <option value="IR">Republica Islamică Iran</option>
                                <option value="MD">Republica Moldova</option>
                                <option value="SC">Republica Seychelles</option>
                                <option value="ZW">Republica Zimbabwe</option>
                                <option value="RE">Réunion</option>
                                <option value="RO">România</option>
                                <option value="RW">Ruanda</option>
                                <option value="EH">Sahara de Vest</option>
                                <option value="WS">Samoa</option>
                                <option value="AS">Samoa Americană</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome și Principe</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia/Kosovo</option>
                                <option value="SH">Sfânta Elena, Înălțarea și Tristan Da Cunha</option>
                                <option value="LC">Sfânta Lucia</option>
                                <option value="BL">Sfântul Bartolomeu</option>
                                <option value="KN">Sfântul Kitts și Nevis</option>
                                <option value="SX">Sfântul Maarten (Partea Olandeză)</option>
                                <option value="MF">Sfântul Martin (Partea Franceză)</option>
                                <option value="PM">Sfântul Pierre și Miquelon</option>
                                <option value="VC">Sfântul Vicențiu și Grenadinele</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SK">Slovacia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SO">Somalia</option>
                                <option value="ES">Spania</option>
                                <option value="ES*">Spania</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="FM">Statele Federale ale Micronesiei</option>
                                <option value="UM">Statele Minore Indepartate ale Statelor Unite</option>
                                <option value="US">Statele Unite ale Americii (SUA)</option>
                                <option value="SD">Sudan</option>
                                <option value="SE">Suedia</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard și Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="TJ">Tadjikistan</option>
                                <option value="TH">Tailanda</option>
                                <option value="TW">Taiwan, Provincia Chinei</option>
                                <option value="TZ">Tanzania</option>
                                <option value="TF">Teritoriile de Sud Franceze</option>
                                <option value="IO">Teritoriul Britanic din Oceanul Indian</option>
                                <option value="PS">Teritoriul Palestinian Ocupat</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad și Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turcia</option>
                                <option value="TM">Turcmenistan</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UA">Ucraina</option>
                                <option value="UG">Uganda</option>
                                <option value="HU">Ungaria</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VA">Vatican (Statul Cetății Vaticanului)</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Vietnam</option>
                                <option value="WF">Wallis și Futuna</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Greutate (kg):</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" name="deliveryWeight" placeholder="" class="form-control" pattern="[0-9]+" required>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Lungime (cm):</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" name="deliveryWidth" placeholder="" class="form-control" pattern="[0-9]+" required>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Lățime (cm):</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" name="deliveryHeight" placeholder="" class="form-control" pattern="[0-9]+" required>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <label>Înălțime (cm):</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" name="deliveryDepth" placeholder="" class="form-control" pattern="[0-9]+" required>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <div class="result">
                                <span>Costul estimativ:</span>
                                <span class="cost">RON 0.00</span>
                            </div>
                        </div>
                    </div>
                </forms>
            </div>
        </div>
    </div>
</section>