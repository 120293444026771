<!-- Portfolio-->
<div id="portfolio">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/legume.jpg">
                    <img class="img-fluid" src="../../assets/images/portfolio/legume.jpg" alt="" />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Produse agroalimentare</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/marfuri-ADR.png">
                    <img class="img-fluid" src="../../assets/images/portfolio/marfuri-ADR.png" alt="" />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Mărfuri periculoase (cu ADR)</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/transport-frigorific.jpg">
                    <img class="img-fluid" src="../../assets/images/portfolio/transport-frigorific.jpg" alt="" />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Produse alimentare/frigorifice</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/mobila.jpg">
                    <img class="img-fluid" src="../../assets/images/portfolio/mobila.jpg" alt="" />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Mobilă</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/colete.jpg">
                    <img class="img-fluid" src="../../assets/images/portfolio/colete.jpg" alt="" />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Colete</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="../../assets/images/portfolio/materiale-constructii.jpg">
                    <img class="img-fluid" src="../../assets/images/portfolio/materiale-constructii.jpg" alt="" />
                    <div class="portfolio-box-caption p-3">
                        <div class="project-category text-white-50">Livrăm</div>
                        <div class="project-name">Materiale de construcții</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>