import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { PreloaderService } from '../preloader.service';
declare var $:any;

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {

  constructor(private preloaderService: PreloaderService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.preloaderService.stop();
   }, 1500);

    $(document).ready(function() {

      function formatState (state) {
        if (!state.id) {
          return state.text;
        }
        var $state = $(
          '<span><span class="flag flag-' + state.element.value.toLowerCase() + '"></span><span>' + state.text + '</span></span>'
        );
        return $state;
      };

      $('#select-delivery').select2({
        placeholder: "Alegeți serviciul dorit"
      });

      $('#select-location-from').select2({
        placeholder: "Alegeți locația",
        templateResult: formatState,
        templateSelection: formatState
      });

      $('#select-location-to').select2({
        placeholder: "Alegeți locația",
        templateResult: formatState,
        templateSelection: formatState
      });
      
   });
  }

}
