import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '../preloader.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private preloaderService: PreloaderService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.preloaderService.stop();
   }, 1500);
  }

}
