 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Echipa noastră</h1>
            </div>
        </div>
    </div>
</header>

<!-- Our team -->
<section class="page-section" id="team">
    <div class="container">
        <div class="row text-center" *ngIf="false">
            <div class="col">
                <div class="widget-team">
                    <a fragment="profile-catalina" routerLink="/team"><img src="../../assets/images/team/catalina-150px.png" alt="Avatar"></a>
                </div>
            </div>
            <div class="col">
                <div class="widget-team">
                    <a fragment="profile-monica" routerLink="/team"><img src="../../assets/images/team/monica-150px.png" alt="Avatar"></a>
                </div>
            </div>
            <div class="col">
                <div class="widget-team">
                    <a fragment="profile-diana" routerLink="/team"><img src="../../assets/images/team/diana-150px.png" alt="Avatar"></a>
                </div>
            </div>
        </div>
        <div class="row svg-row">
            <div class="col svg">
                <svg width="888" height="809" viewBox="0 0 888 809" fill="none" xmlns="http://www.w3.org/2000/svg" class="css-jkl1fc"><circle cx="441" cy="413" r="395.25" transform="rotate(-180 441 413)" stroke="#F5F5F5" stroke-width="1.5"></circle><path d="M105.75 413C105.75 227.846 255.846 77.75 441 77.75C626.153 77.75 776.25 227.846 776.25 413C776.25 598.153 626.153 748.25 441 748.25C255.846 748.25 105.75 598.153 105.75 413Z" stroke="#F0F0F2" stroke-width="1.5"></path><ellipse cx="96" cy="215" rx="7.99996" ry="8" fill="#FEC21E"></ellipse><ellipse cx="276.235" cy="123" rx="6.00001" ry="6" fill="#59C156"></ellipse><ellipse cx="774" cy="198" rx="6.00026" ry="6" fill="#B17ACC"></ellipse><ellipse cx="192.999" cy="637" rx="5.99947" ry="6" fill="#F67300"></ellipse><ellipse cx="695.2" cy="635.956" rx="10.0001" ry="10" fill="#DBF0FF"></ellipse><ellipse cx="6.00014" cy="525" rx="6.00014" ry="6" fill="#E899CE"></ellipse><ellipse cx="879" cy="400" rx="7.99983" ry="8" fill="#BCE939"></ellipse></svg>
                <div class="svg-title-subtitle">
                    <h1 class="h1-title">Succesul este efortul <span>în echipă.</span></h1>
                    <p class="p-subtitle">"Să formezi o echipă este doar începutul, să rămâi împreună este progresul, să lucrezi împreună este succesul."<br/><strong> - Henry David Thoreau</strong></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-section" id="profiles">
    <div class="container lg-size">
        <div class="row separator">
            <div class="col-lg">
                <div class="row widget-profile">
                    <img src="../../assets/images/team/cata.jpg" alt="Avatar"/>
                    <h4>Catalina Elena Enescu</h4>
                    <h6>Manager transport si trafic</h6>
                    <ul class="contact">
                        <li><strong>Email: </strong> <span><a href="mailto:catalina@cmdtruck.ro">catalina@cmdtruck.ro</a></span></li>
                        <li><strong>Telefon: </strong> <span><a href="tel:+40759828618">(+40) 759 828 618</a></span></li>
                        <li><strong>Whatsapp: </strong> <span><a target="_blank" href="https://wa.me/0040759828618">(+40) 759 828 618</a></span></li>
                    </ul>
                    <ul class="social-media">
                        <li><a target="_blank" href="http://facebook.com/profile.php?id=100006377464271"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/enescu_catalina/"><i class="fab fa-instagram"></i></a></li>
                        <li><a target="_blank" href="https://cmdtruck.ro/"><i class="fas fa-globe"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg" *ngIf="false">
                <div class="row widget-profile">
                    <img src="../../assets/images/team/moni.jpg" alt="Avatar"/>
                    <h4>Monica Elena Tudosie</h4>
                    <h6>Inginer transport si trafic</h6>
                    <ul class="contact">
                        <li><strong>Email: </strong> <span><a href="mailto:monica@cmdtruck.ro">monica@cmdtruck.ro</a></span></li>
                        <li><strong>Telefon: </strong> <span><a href="tel:+40758931606">(+40) 758 931 606</a></span></li>
                        <li><strong>Whatsapp: </strong> <span><a target="_blank" href="https://wa.me/0040758931606">(+40) 758 931 606</a></span></li>
                    </ul>
                    <ul class="social-media">
                        <li><a target="_blank" href="http://facebook.com/monicaelena.tudosie"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a target="-blank" href="https://www.instagram.com/monicaelenatudosie/"><i class="fab fa-instagram"></i></a></li>
                        <li><a target="_blank" href="https://cmdtruck.ro/"><i class="fas fa-globe"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg">
                <div class="row widget-profile last-w">
                    <img src="../../assets/images/team/dia.jpg" alt="Avatar"/>
                    <h4>Diana Elena Enescu</h4>
                    <h6>Director general</h6>
                    <ul class="contact">
                        <li><strong>Email: </strong> <span><a href="mailto:diana@cmdtruck.ro">diana@cmdtruck.ro</a></span></li>
                        <li><strong>Telefon: </strong> <span><a href="tel:+40759826003">(+40) 759 826 003</a></span></li>
                        <li><strong>Whatsapp: </strong> <span><a target="_blank" href="https://wa.me/0040759826003">(+40) 759 826 003</a></span></li>
                    </ul>
                    <ul class="social-media">
                        <li><a target="_blank" href="http://facebook.com/diana.dydy018"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/elena.diana.19/"><i class="fab fa-instagram"></i></a></li>
                        <li><a target="_blank" href="https://cmdtruck.ro/"><i class="fas fa-globe"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>