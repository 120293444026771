<!-- About-->
<section class="page-section bg-primary" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
                <h2 class="text-white mt-0">Misiunea noastră</h2>
                <hr class="divider light my-4" />
                <p class="text-white-50 mb-4">
                    Vă oferim servicii de transport atât interne cât și internaționale de cea mai bună calitate. Firma noastră pune la dispoziția clienților o gamă largă de servicii care cuprinde atât transportul cât și asigurarea mărfii transportate.
                    <br/><br/>
                    De asemenea, deținem o varietate de vehicule în funcție de tipul de marfă pe care il aveți de transportat.
                    </p>
                <p class="text-white-50 mb-4">Ai nevoie de o ofertă de preț?</p>
                <a class="btn btn-light btn-xl js-scroll-trigger" routerLink="/contact">CERE O OFERTĂ</a>
            </div>
        </div>
    </div>
</section>