 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Contact</h1>
            </div>
        </div>
    </div>
</header>

<!-- Contact -->
<section class="page-section" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="contact-widget">
                    <h3>INFORMAȚII COMPANIE</h3>
                    <p class="mb-2"><i class="fas fa-map-marker-alt"></i> <a target="_blank" href="http://maps.google.com/?q=Str.%20Vasile%20Aaron,%20Nr.%2022,%20Sibiu">Str. Vasile Aaron, Nr. 22, Sibiu, România</a></p>
                    <p class="mb-2"><i class="fa fa-phone"></i> <a href="tel:+40759828618">(+40) 759 828 618</a></p>
                    <p class="mb-2"><i class="fab fa-whatsapp"></i> <a target="_blank" href="https://wa.me/0040759828618">(+40) 759 828 618</a></p>
                    <p class="mb-2"><i class="fa fa-envelope"></i> <a href="mailto:office@cmdtruck.ro">office@cmdtruck.ro</a></p>
                    <p class="mb-2"><i class="far fa-clock"></i> Luni - Vineri: 8:30 - 17:30</p>
                </div>
            </div>
            <div class="col-lg-8 pl-5">
                <div class="contact-form-widget">
                    <h3>SOLICITAREA DUMNEAVOASTRĂ</h3>
                    <p>Suntem aproape de tine oriunde ai fi. Completați următorul formular pentru a ne trimite o întrebare sau solicitare și vă vom răspunde în cel mai scurt timp posibil:</p>
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" name="name" placeholder="Nume" class="form-control" formControlName="contactFormName" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" name="phone" placeholder="Telefon" class="form-control" formControlName="contactFormPhone" pattern="[0-9]+" required>
                            </div>
                            <div class="col-lg-12">
                                <input type="email" name="email" placeholder="Adresă de email" formControlName="contactFormEmail" class="form-control" required>
                            </div>
                            <div class="col-lg-12">
                                <textarea rows="6" name="message" placeholder="Mesaj" class="form-control" formControlName="contactFormMessage" required=""></textarea>
                            </div>
                            <div class="col-lg-8">
                                <button type="submit" name="submit" value="Send">TRIMITE</button>
                            </div>
                            <div [ngClass]="!submitted? 'd-none' : 'd-block'" class="col-lg-12 mt-3 fade-in">
                                <div class="form-message">{{responseMessage}}</div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <br/><br/>
    <iframe
  width="100%"
  height="500"
  frameborder="0" style="border:0;margin-bottom: -6px;"
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBAudqh1R80FgtoLQrOyo8Dl0N9XVahmfs&q=Strada+Vasile+Aaron+22,+Sibiu" allowfullscreen>
</iframe>
</section>