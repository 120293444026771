import { OnInit, Component } from '@angular/core';
import { PreloaderService } from '../preloader.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private preloaderService: PreloaderService) { }

  ngOnInit(): void { 
    setTimeout(() => {
      this.preloaderService.stop();
   }, 3000);
  }
  
}
