<!-- Trusted Brand -->
<section class="section-trusted">
    <div class="background-overlay"></div>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md trusted-left">
                <div class="row trusted-title"><h1>100+</h1></div>
                <div class="row mt-3 trusted-title"><h2>Clienți au încredere în noi. Uitați de ce:</h2></div>
                <div class="row mt-4 pt-1 trusted-title"><p>Compania C.M.D. IRIS TRUCK SRL vă oferă servicii de calitate, rapiditate și siguranța mărfurilor transportate.</p></div>
            </div>
            <div class="col-md trusted-right">
                <div class="row text-center">
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-1.png" alt=""/>
                            <p>Soluții moderne</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-2.png" alt=""/>
                            <p>Monitorizare gratuită</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-3.png" alt=""/>
                            <p>Livrare globală</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 pt-1 text-center">
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-4.png" alt=""/>
                            <p>Depozitare</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-5.png" alt=""/>
                            <p>Rapiditate</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="img-widget">
                            <img width="102" height="102" src="../../assets/images/trusted/icon-6.png" alt=""/>
                            <p>Seriozitate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>