 <!-- Masthead-->
 <header class="masthead">
    <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center text-center">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">CMD Truck<br/>Transporturi și Logistică</h1>
                <hr class="divider my-4" />
            </div>
            <div class="col-lg-8 align-self-baseline">
                <p class="text-white-75 font-weight-light mb-5">Noi reducem distanțele și asigurăm mobilitatea bunurilor, iar evoluția acestui domeniu ne schimbă viața sub toate aspectele sale: economic, politic, cultural și ecologic.</p>
                <a class="btn btn-primary btn-xl js-scroll-trigger" fragment="about" routerLink="/home">AFLĂ MAI MULTE</a>
            </div>
        </div>
    </div>
</header>

 <!-- About section-->
<app-about></app-about>

<!-- Services section-->
<app-services></app-services>

<!-- Trusted section-->
<app-trusted></app-trusted>

<!-- Portofolio section-->
<app-portofolio></app-portofolio>

<!-- Testimonials section-->
<app-testimonials></app-testimonials>
