<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css" integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g==" crossorigin="anonymous" />

<!-- Testimonials -->
<section class="testimonails-section">
    <div class="section-title text-center">
        <div class="container">
            <div class="row">
                <div class="col"></div>
                <div class="col-lg-8">
                    <div class="title-box-center">
                        <h5 class="h5-title mb-3">Recenzii</h5>
                        <h2 class="h2-title">Ce spun clienții despre noi?</h2>
                    </div>
                </div>
                <div class="col"></div>
            </div>
        </div>
    </div>
    <div class="section-content">
        <div class="container">
            <div class="row">
                <div class="owl-carousel owl-rtl owl-loaded owl-drag">
                    <div class="testimonial-item">
                        <span class="quote-icon"><i class="fas fa-quote-right"></i></span>
                        <div class="testimonial-thumb">
                            <img src="../../assets/images/testimonials/testimonial-img1.jpg" alt="Avatar">
                        </div>
                        <h4 class="client-name">Mădălin E.</h4>
                        <h6 class="client-company">Antreprenor</h6>
                        <ul class="star-rating">
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                        </ul>
                        <div class="testimonial-comment">
                            <p>O echipă dinamică și responsabilă. Promptitudine și rapiditate.</p>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <span class="quote-icon"><i class="fas fa-quote-right"></i></span>
                        <div class="testimonial-thumb">
                            <img src="../../assets/images/testimonials/testimonial-img2.jpg" alt="Avatar">
                        </div>
                        <h4 class="client-name">Andreea U.</h4>
                        <h6 class="client-company">Team Assistant</h6>
                        <ul class="star-rating">
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star-half-alt"></i></li>
                        </ul>
                        <div class="testimonial-comment">
                            <p>Firmă de transport mărfuri serioasă și responsabilă.</p>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <span class="quote-icon"><i class="fas fa-quote-right"></i></span>
                        <div class="testimonial-thumb">
                            <img src="../../assets/images/testimonials/testimonial-img3.png" alt="Avatar">
                        </div>
                        <h4 class="client-name">Andreas A.</h4>
                        <h6 class="client-company">Inginer Software</h6>
                        <ul class="star-rating">
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                        </ul>
                        <div class="testimonial-comment">
                            <p>Siguranță, profesionalism și implicare 100%. O echipă pregătită de colaborare.</p>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <span class="quote-icon"><i class="fas fa-quote-right"></i></span>
                        <div class="testimonial-thumb">
                            <img src="../../assets/images/testimonials/testimonial-img4.jpg" alt="Avatar">
                        </div>
                        <h4 class="client-name">Mihaela T.</h4>
                        <h6 class="client-company">Manager</h6>
                        <ul class="star-rating">
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                        </ul>
                        <div class="testimonial-comment">
                            <p>Încredere, dăruire și perseverență.</p>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>