 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Despre noi</h1>
            </div>
        </div>
    </div>
</header>

<!-- About us -->
<section class="page-section" id="about-us">
    <div class="container">
        <h3 class="text-center mt-0">Află mai multe despre noi</h3>
        <hr class="divider my-4" />
        <div class="container mt-5 pt-5">
            <div class="row">
                <div class="col-lg-6">
                    <p class="description">
                        C.M.D. IRIS TRUCK SRL este o  companie ce sprijină și soluționează probleme legate de logistică și transport pentru actualii și potențialii ei clienți, oferindu-le o gamă diversificată de servicii ce vizează: depozitarea si transportul mărfii.
                        <br/><br/>
                        Sediul companiei se află în Sibiu, pe strada Vasile Aaron, nr. 22.
                    </p>
                    <ul>
                        <li>
                            <img src="../../assets/images/aboutus/fast-delivery.png" alt="FAST DELIVERY"/>
                            <div>
                                <h4>RAPID</h4>
                                <p>Oferim transport rapid cu livrare în aceeași zi sau ziua următoare, la tarife avantajoase</p>
                            </div>
                        </li>
                        <li>
                            <img src="../../assets/images/aboutus/secured.png" alt="SECURED SERVICE"/>
                            <div>
                                <h4>SIGUR</h4>
                                <p>Transport rutier de înaltă calitate și securitate pentru bunurile dumneavoastră</p>
                            </div>
                        </li>
                        <li>
                            <img src="../../assets/images/aboutus/worldwide.png" alt="WORLDWIDE SHIPPING"/>
                            <div>
                                <h4>GLOBAL</h4>
                                <p>Oferim servicii profesionale de transport marfă atât la nivel național cât și internațional</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 text-center">
                    <img src="../../assets/images/aboutus/bg-aboutus.png" alt="Car Background" class="bg-car-aboutus"/>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-3">
            <div class="row">
                <div class="col-lg-4">
                    <div class="slogan-widget">
                        <h4>Seriozitate</h4>
                        <p>Oferim seriozitate, ne respectăm promisiunile asumate şi respectăm normele şi cerințele legale.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="slogan-widget">
                        <h4>Profesionalism</h4>
                        <p>Manifestăm permanent o atitudine corectă şi deschisă în relaţiile dintre noi.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="slogan-widget">
                        <h4>Promptitudine</h4>
                        <p>Oferim informaţii la timp şi în mod deschis. Punem accent deosebit pe corectitudine şi transparenţă.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>