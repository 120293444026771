<!-- Services-->
<section class="page-section" id="services">
    <div class="container">
        <h2 class="text-center mt-0">Cine suntem și <span style="color:#f4623a;">ce oferim?</span></h2>
        <hr class="divider my-4" />
        <div class="row">
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-heart text-primary mb-4"></i>
                    <h3 class="h4 mb-2">Brand Românesc</h3>
                    <p class="text-muted mb-0">Suntem o companie românească și sprijinim economia locală și micii întreprinzători.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-gem text-primary mb-4"></i>
                    <h3 class="h4 mb-2">Calitate</h3>
                    <p class="text-muted mb-0">Experiența, calificarea și implicarea personalului stau la baza unor soluții de transport personalizate, flexibile și creative.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                    <h3 class="h4 mb-2">Monitorizare</h3>
                    <p class="text-muted mb-0">Supravegherea pas cu pas a întregului proces de transport și informații permanente privind poziția mărfii.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-globe text-primary mb-4"></i>
                    <h3 class="h4 mb-2">Transport Global</h3>
                    <p class="text-muted mb-0">Într-o economie în care timpul este valoros, noi oferim clienților noştri posibilitatea de a rezolva orice problemă de transport cu un singur telefon.</p>
                </div>
            </div>
        </div>
    </div>
</section>