import { ConnectionService } from '../connection.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '../preloader.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted: boolean = false; // show and hide the success message
  responseMessage: string; // the response message to show to the user

  constructor(private fb: FormBuilder, private connectionService: ConnectionService, private preloaderService: PreloaderService) {

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormPhone': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormMessage': ['', Validators.required]
      });
    }
  
    onSubmit() {
      if (this.contactForm.status == "VALID") {
        this.contactForm.disable(); // disable the form if it's valid to disable multiple submissions
        this.submitted = false; // hide the response message on multiple submits

        this.connectionService.sendMessage(this.contactForm.value).subscribe(() => {
          this.responseMessage = "Succes! Mesajul dvs. a fost trimis. Vă vom contacta în cel mai scurt timp posibil.";
          this.contactForm.reset();
          this.contactForm.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
        }, error => {
          this.responseMessage = "Oops! Ceva nu a mers bine... Vă rugăm să încercați din nou.";
          this.contactForm.reset();
          this.contactForm.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          console.log('Error', error);
        });
      }
    }

    ngOnInit() {
      setTimeout(() => {
        this.preloaderService.stop();
     }, 1500);
    }
  
  }
