<!-- Footer-->
<footer>
    <div class="footer-content">
        <div class="container" style="max-width: 1250px;">
            <div class="row">
                <div class="col-md">
                    <div class="widget">
                        <h5>ABOUT US</h5>
                        <div class="logo-text">CMD <span>TRUCK</span></div>
                        <p>C.M.D. IRIS TRUCK SRL este o  companie ce sprijină și soluționează probleme legate de logistică și transport pentru actualii și potențialii ei clienți, oferindu-le o gamă diversificată de servicii ce vizează: depozitarea și transportul mărfii.</p>
                    </div>
                </div>
                <div class="col-md pl-5">
                    <div class="widget">
                        <h5>SOCIAL MEDIA</h5>
                        <ul class="social-media">
                            <li><a target="_blank" href="https://fb.me/cmdtruck.ro">
                                <span class="social-icon"><i class="fab fa-facebook-f"></i></span><span class="social-tag">@cmdtruck.ro</span>
                            </a></li>
                            <li><a target="_blank" href="https://twitter.com/CmdTruck">
                                <span class="social-icon"><i class="fab fa-twitter"></i></span><span class="social-tag">@CmdTruck</span>
                            </a></li>
                            <li><a target="_blank" href="https://www.instagram.com/cmdtruck/">
                                <span class="social-icon"><i class="fab fa-instagram"></i></span><span class="social-tag">@cmdtruck</span>
                            </a></li>
                            <li><a target="_blank" href="http://pinterest.com/cmdtruckro">
                                <span class="social-icon"><i class="fab fa-pinterest"></i></span><span class="social-tag">@cmdtruckro</span>
                            </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md">
                    <div class="widget contact-section">
                        <h5>CONTACT INFO</h5>
                        <p class="mb-2"><i class="fas fa-map-marker-alt"></i> <a target="_blank" href="http://maps.google.com/?q=Str.%20Vasile%20Aaron,%20Nr.%2022,%20Sibiu">Str. Vasile Aaron, Nr. 22, Sibiu, România</a></p>
                        <p class="mb-2"><i class="fa fa-phone"></i> <a href="tel:+40759828618">(+40) 759 828 618</a></p>
                        <p class="mb-2"><i class="fab fa-whatsapp"></i> <a target="_blank" href="https://wa.me/0040759828618">(+40) 759 828 618</a></p>
                        <p class="mb-2"><i class="fa fa-envelope"></i> <a href="mailto:office@cmdtruck.ro">office@cmdtruck.ro</a></p>
                        <p class="mb-2"><i class="far fa-clock"></i> Luni - Vineri: 8:30 - 17:30</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="text-center">
                        Crafted with <i class="fas fa-heart" style="color:red;"></i> by Andreas<br/>Copyright © {{ currentYear }} by <span style="color:#FF4800;">CMD Truck</span> | All rights reserved
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="scroll-to-top"><i class="fa fa-angle-up"></i></div>
    <div class="call-us"><i class="fas fa-phone-alt"></i></div>
</footer>